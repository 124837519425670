import React, {Component} from 'react';
import Header from '../components/Header/Header';
import { Layout } from '../layout/Layout';

class Resume extends Component {
    render() {
        return (
            <>
                <Layout>
                    <Header active="resume"/>
                    <iframe title="Resume" src="https://drive.google.com/file/d/1tcIU7rCd099QdJmyGJLVYfOsaBYBIpul/preview" width="100%" style={{height: `calc(100vh - 52px)`}} />
                </Layout>
            </>
        )
    }
}

export default Resume